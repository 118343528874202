import React from 'react';
import 'src/assets/styles/parts/Blog.css'

interface BlogCardProps {
  id: number;
  images: string;
  title: string;
  subtitle: string;
  time: string;
  content: string;
}

const BlogCard: React.FC<BlogCardProps> = ({
  images,
  title,
  subtitle,
  time
}) => {
  return (
    <div className="blog-card">
      <img src={require(`../assets/images/blog/${images}`)} alt={title} className="blog-card__image" />
      <div className="blog-card__content">
        <h3 className="blog-card__title">{title}</h3>
        <p className="blog-card__subtitle">{subtitle}</p>
        <p className="blog-card__time">{time}</p>
      </div>
    </div>
  );
};

export default BlogCard;