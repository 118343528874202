import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import blogData from "../configs/blogData.json";
import "../assets/styles/parts/BlogDetail.css";

const BlogDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const numericId = Number(id);

  const blogPost = blogData.find((blog) => blog.id === numericId);

  if (!blogPost) {
    return <div>Blog post not found</div>;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  // Xử lý content để thay thế #image bằng hình ảnh từ blogPost.images
  const renderContent = () => {
    const contentParts = blogPost.content.split("#image"); // Tách content bằng #image
    return contentParts.map((part, index) => (
      <React.Fragment key={index}>
        <p>{part}</p>
        {index < blogPost.images.length && (
          <img
            src={require(`../assets/images/blog/${blogPost.images[index]}`)}
            alt=""
            className="blog-detail__inline-image"
          />
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className="blog-detail-container">
      <button className="blog-detail__back-button" onClick={handleGoBack}>
        ← Back
      </button>

      <div className="blog-detail__header">
        <div className="blog-detail__header-content">
          <h1 className="blog-detail__title">{blogPost.title}</h1>
          <p className="blog-detail__subtitle">{blogPost.subtitle}</p>
          <div className="blog-detail__meta">
            <span>{blogPost.time}</span>
          </div>
        </div>
      </div>

      <div className="blog-detail__content">{renderContent()}</div>
    </div>
  );
};

export default BlogDetail;
