import React, { useCallback, useEffect, useRef, useState } from "react";
import "../assets/styles/parts/OurStory.css";
import storyConfig from "../configs/story.json";

function OurStory() {
  const itemListRef = useRef<HTMLDivElement>(null);
  const sliderWrapperRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef<HTMLDivElement>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const storyElementRef = useRef<HTMLDivElement>(null);

  const [currentSelected, setCurrentSelected] = useState(0);
  const [fade, setFade] = useState(false);
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  useEffect(() => {
    const resizeScrollBar = () => {
      if (window.screen.width < 768) return;
      if (
        itemListRef.current &&
        sliderWrapperRef.current &&
        sliderRef.current
      ) {
        const itemListHeight = itemListRef.current.getBoundingClientRect();
        sliderWrapperRef.current.style.height = `${itemListHeight.height}px`;
      }
    };
    resizeScrollBar();
    window.addEventListener("resize", resizeScrollBar);
    return () => {
      window.removeEventListener("resize", resizeScrollBar);
    };
  }, []);

  const handleImageChange = (index: number) => {
    setFade(true);
    setTimeout(() => {
      setCurrentSelected(index);
      setFade(false);
    }, 500); // Duration of the fade-out transition
  };

  const startScrollLoop = useCallback(() => {
    if (scrollInterval) return;
    const interval = setInterval(() => {
      handleImageChange((currentSelected + 1) % storyConfig.images.length);
    }, storyConfig.sliderSpeed);
    setScrollInterval(interval);
  }, [currentSelected, scrollInterval]);

  const stopScrollLoop = useCallback(() => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  }, [scrollInterval]);

  useEffect(() => {
    if (!storyElementRef.current) return;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            startScrollLoop();
          } else {
            stopScrollLoop();
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentElement = storyElementRef.current;
    observerRef.current.observe(currentElement);

    return () => {
      if (observerRef.current && currentElement) {
        try {
          observerRef.current.unobserve(currentElement);
          observerRef.current.disconnect();
        } catch (error) {
          console.error("Error unobserving element:", error);
        }
      }
      stopScrollLoop();
    };
  }, [startScrollLoop, stopScrollLoop]);

  return (
    <div className="os-wrapper" id="story" ref={storyElementRef}>
      <div className="os-title-container">
        <div className="os-title">
          BRAND STORY <br /> THIN BARBERSHOP
        </div>
      </div>
      <div className="os-content-wrapper">
        <div
          className={`os-display ${fade ? "fade-out" : "fade-in"}`}
          style={{
            backgroundImage: `url(${require(`../assets/images/${storyConfig.images[currentSelected]}`)})`,
          }}
        >
          <div className="os-display-text-wrapper">
            <div className="os-display-text-container">
              <div className="os-display-text">
                {storyConfig.stories[currentSelected]}
              </div>
            </div>
          </div>
        </div>
        <div className="os-img-slider">
          <div className="os-slider" ref={sliderWrapperRef}>
            <div className="sb-wrapper">
              {/* <ScrollBar divRef={sliderRef} thumbHeight={thumbHeight} scrollContainer={itemListRef}/> */}
            </div>
          </div>
          <div className="os-img-list" ref={itemListRef}>
            {getStory(handleImageChange)}
          </div>
        </div>
      </div>
    </div>
  );
}

function getStory(setCurrentSelected: (index: number) => void) {
  return storyConfig.images.map((s, index) => {
    return (
      <img
        className="os-img-item"
        src={require(`../assets/images/${s}`)}
        alt={`Our Story - ${index}`}
        key={index}
        onClick={() => setCurrentSelected(index)}
      />
    );
  });
}

export default OurStory;
