import React from "react";

import "../assets/styles/component/WorkingHour.css";

import serviceConfig from "../configs/services.json";

function WorkingHour() {

    return (
        <div className={"wh-wrapper"}>
            <div className={"wh-main-container"}>
                <div className={"wh-title-mobile"}>
                    WORKING HOURS
                </div>
                <div className={"wh-content-container"}>
                    {
                        serviceConfig.workingTime.map((workingHour, index) => {
                            return (
                                <div key={index} className={"wh-item"}>
                                    <div className={"wh-item-day"}>
                                        {workingHour.day}
                                    </div>
                                    <div className={"wh-item-time"}>
                                        {workingHour.time}
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
                <div className={"wh-content-deco-line"}/>
            </div>
            <div className={"wh-decor-container"}>
                <div className={"wh-decor-img"}>
                    <div className={"wh-decor-img-shadow"}/>
                    <img className={"wh-decor-img-bg"} src={require(`../assets/images/${serviceConfig.workingImage}`)} alt={"abc"}/>
                </div>
                <div className={"wh-title-web"}>
                    HOURS
                </div>
            </div>
        </div>
    );
}

export default WorkingHour;