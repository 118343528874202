import React from "react";

import "../assets/styles/parts/OurServices.css";

import servicesConfig from "../configs/services.json";

import WorkingHour from "../components/WorkingHour";
import OurMission from "../components/OurMission";

function OurServices() {
  const services = servicesConfig.services;

  const [selectedService, setSelectedService] = React.useState(0);

  const handleViewMore = () => {
    window.open('https://forms.gle/zm7HPsvdrrpz4PW6A', '_blank');
  };

  return (
    <div className={"oser-wrapper"} id={"service"}>
      <div className={"oser-top-container"}>
        <div className={"oser-top-content-container"}>
          <div className={"oser-top-right-container"}>
            <h1 className={"oser-title"}>DỊCH VỤ</h1>
            <div className={"oser-description"}>
              {servicesConfig.description}
            </div>
          </div>
          <div className={"oser-service-container"}>
            <div className="pricing-tab-container">
              <div className="tab-filter">
                {services.map((service, index) => (
                  <div>
                    <div
                      className={
                        "filter-btn" +
                        (selectedService === index ? " active" : "")
                      }
                      data-filter-btn="all"
                      onClick={() => setSelectedService(index)}
                    >
                      <div className="btn-icon">
                        <img src={require(`../assets/images/iconservice/${service.tabIcon}`)} alt={service.title} />
                      </div>

                      <div className="btn-text">{service.title}</div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="grid-list">
                {services[selectedService].contents.map((content, index) => (
                  <div className={"active"} id={content.title}>
                    <div className="pricing-card">
                      <figure className="card-banner img-holder">
                        <img
                          src={require(`../assets/images/iconservice/${content.image}`)}
                          alt="Hair Cutting & Fitting"
                          className="img-cover"
                        />
                      </figure>

                      <div className={"card-des-wrapper"}>
                        <div className="wrapper">
                          <h3 className="h3 card-title">{content.title}</h3>

                          <p className="card-text">{content.subtitle}</p>
                        </div>

                        <div className="price-and-action">
                          <data className="card-price" value="89">
                            {content.price}
                          </data>
                          { selectedService === 4 && (
                            <button 
                              className="view-more-btn"
                              onClick={handleViewMore}
                            >
                              Xem thêm
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <WorkingHour />

      <OurMission />
    </div>
  );
}

export default OurServices;